import { registerPlugin } from "@capacitor/core";
import { NativeWebSocketWeb } from "./web";
import type { NativeWebSocketPlugin } from "./definitions";

const NativeWebSocket = registerPlugin<NativeWebSocketPlugin>("NativeWebSocket", {
	web: () => (new NativeWebSocketWeb()),
	android: () => (new NativeWebSocketWeb()),
});

export type * from "./definitions";
export { NativeWebSocket };
