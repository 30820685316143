import { registerPlugin } from "@capacitor/core";
import { NativeLoginWeb } from "./web";
import type { NativeLoginPlugin } from "./definitions";

const NativeLogin = registerPlugin<NativeLoginPlugin>("NativeLogin", {
	web: () => (new NativeLoginWeb()),
});

export type * from "./definitions";
export { NativeLogin };
