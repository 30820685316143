import { useEffect, useState } from "react";

const getIsKeyboardOpen = (minKeyboardHeight: number) => (
	// could be also undefined
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	window.visualViewport !== undefined && window.visualViewport !== null && window.visualViewport.height < window.screen.height - minKeyboardHeight
);

const useIsKeyboardOpen = (minKeyboardHeight = 300) => {
	const [isKeyboardOpen, setIsKeyboardOpen] = useState(getIsKeyboardOpen(minKeyboardHeight));

	useEffect(() => {
		const handleResizeListener = () => (setIsKeyboardOpen(getIsKeyboardOpen(minKeyboardHeight)));

		window.visualViewport?.addEventListener("resize", handleResizeListener);

		return () => {
			window.visualViewport?.removeEventListener("resize", handleResizeListener);
		};
	}, [minKeyboardHeight]);

	return isKeyboardOpen;
};

export default useIsKeyboardOpen;
